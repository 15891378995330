.no-left-border-radius {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    //border-left: 0px !important;
}

.no-right-border-radius {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    //border-right: 0px !important;
}

#all-buttons-container {
    display: inline-flex;
}

.main-buttons-container {
    margin-bottom: 1rem;
    display: flex;
    color: white;
}

.main-btn {
    width: 10rem;
}

.btn-mr {
    margin-right: 0.2rem;
}

.dt-btn {
    width: 5rem;
}

.btn-crud {
    background-color: $main-theme !important;
    color: white !important;
    // For gradient
    //background-image: -webkit-gradient(linear,right top, left top,from(rgba(46,182,151,.18)),to(transparent)) !important;
    //background-image: linear-gradient(270deg, rgba(46,182,151,.18),transparent) !important;
}

.btn-crud:hover {
    background-color: $main-theme-hover !important;
}

.btn-force-delete {
    background-color: rgb(230, 64, 64);
    color: white !important;
    // For gradient
    //background-image: -webkit-gradient(linear,right top, left top,from(rgba(46,182,151,.18)),to(transparent)) !important;
    //background-image: linear-gradient(270deg, rgba(46,182,151,.18),transparent) !important;
}

.btn-force-delete:hover {
    background-color: rgb(190, 37, 37);
}

.btn-bulk-restore {
    color: white;
    background-color: #f9de45;
    box-shadow: 0 2px 6px 0 rgba(233, 236, 47, 0.466)
}

.btn-bulk-restore:hover {
    color: white;
    background-color: #d3ba2e;
}

.btn-bulk-delete {
    color: white;
    background-color: rgb(250, 21, 21);
    box-shadow: 0 2px 6px 0 rgba(216, 16, 16, 0.589)
}

.btn-bulk-delete:hover {
    color: white;
    background-color: rgb(214, 30, 30);
}

.btn-secondary-white {
    color: $color-primary;
    background-color: white;
    border: 1px solid #6a7eb5;
    box-shadow: 0 2px 6px 0 rgb(255 255 255 / 50%);
}

.btn-secondary-white:hover {
    background-color: rgb(252, 252, 252);
}

/*
.btn-success {
    background-color: #3caf16;
    border: none;
    box-shadow: none;
}

.btn-success:hover {
    background-color: #0da014;
}

.btn-danger {
    background-color: #d12b0d;
    border: none;
    box-shadow: none;
}

.btn-danger:hover {
    background-color: #c42f15;
}

.btn-info {
    background-color: #1dc7a0;
    //border: none;
    box-shadow: none;
}

.btn-info:hover {
    background-color: #13a583;
}

.btn-warning {
    background-color: #ffd541;
    border: none;
    box-shadow: none;
}

.btn-warning:hover {
    background-color: #dfba34;
}

.btn-primary {
    background-color: $main-theme !important;
    color: white !important;
    // For gradient
    //background-image: -webkit-gradient(linear,right top, left top,from(rgba(46,182,151,.18)),to(transparent)) !important;
    //background-image: linear-gradient(270deg, rgba(46,182,151,.18),transparent) !important;
    //border: none;
    box-shadow: none;
}

.btn-primary:hover {
    background-color: $main-theme-hover !important;
}
*/
